export const errorHandler = (err) => {
  if (typeof err === "string") {
    return err;
  }
  if (err.response) {
    const { data } = err.response;
    if (data.non_field_errors) {
      if (data.non_field_errors.length === 1) {
        return data.non_field_errors[0];
      }
      return data.non_field_errors;
    }
    if (data.message) {
      return data.message;
    }
    if (data.detail) {
      return data.detail;
    }
    if (data.error) {
      return data.error;
    }
    const first = Object.keys(data);
    return data[first];
  }
  return "An unexpected error occurred";
};
