import React, { useContext } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { AuthContext } from "../contexts";

export const PrivateRoute: React.FC<RouteProps> = (props) => {
  const { user } = useContext(AuthContext);
  return user ? (
    <Route {...props} component={props.component} />
  ) : (
    <Redirect to="/login" />
  );
};
