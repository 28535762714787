import React, {useContext, useEffect, useState} from 'react';
import { Formik } from 'formik';
import {
    Breadcrumb, Button, Card, Col, Form, Row
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { webSocket } from 'rxjs/webSocket';
import { useHistory } from 'react-router-dom';
import Map from './Map';
import { useAuthFetch } from "../helpers";
import {baseURL, wsBaseURL} from "../config";
import {AuthContext} from "../contexts";

function RiderRequestForm({ data }) {
    const { user, tokens: { access } } = useContext(AuthContext)

    useEffect(() => {
        if (window.navigator.geolocation) {
            window.navigator.geolocation.getCurrentPosition((position) => {
                setLat(position.coords.latitude);
                setLng(position.coords.longitude);
            });
        }
    }, []);

    const history = useHistory()
    const [lat, setLat] = useState(-33.865143);
    const [lng, setLng] = useState(	151.209900);

    const onSubmit = (values, actions) => {
        const rider = user
        const ws = webSocket(`${wsBaseURL}/taxi/?token=${access}`);
        ws.subscribe();
        ws.next({
            type: 'create.trip',
            data: {
                pickup_address: values.pickUpAddress,
                drop_off_address: values.dropOffAddress,
                rider: rider.id
            }
        });
        history.push('/rider')
    };

    return (
        <Formik
            initialValues={{
                pickUpAddress: '',
                dropOffAddress: ''
            }}
            onSubmit={onSubmit}
        >
            {({
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  values
              }) => (
                <Form noValidate onSubmit={handleSubmit}>

                    {/*<Form.Group controlId="pickUpAddress">*/}
                    {/*    <Form.Label>Pickup Address select</Form.Label>*/}
                    {/*    <Form.Control as="select">*/}
                    {/*        <option>1</option>*/}
                    {/*    </Form.Control>*/}
                    {/*</Form.Group>*/}
                    {/*<Form.Group controlId="dropOffAddress">*/}
                    {/*    <Form.Label>Dropoff Address</Form.Label>*/}
                    {/*    <Form.Control as="select">*/}
                    {/*        <option>1</option>*/}
                    {/*    </Form.Control>*/}
                    {/*</Form.Group>*/}


                    <Form.Group controlId='pickUpAddress'>
                        <Form.Label>
                            Pick up address:
                        </Form.Label>
                        <Form.Control
                            data-cy='pick-up-address'
                            name='pickUpAddress'
                            onChange={handleChange}
                            values={values.pickUpAddress}
                            required
                        />
                    </Form.Group>
                    {/* new */}
                    {/*<Map*/}
                    {/*    lat={lat}*/}
                    {/*    lng={lng}*/}
                    {/*    zoom={13}*/}
                    {/*    pickUpAddress={values.pickUpAddress}*/}
                    {/*    dropOffAddress={values.dropOffAddress}*/}
                    {/*/>*/}
                    <Form.Group controlId='dropOffAddress'>
                        <Form.Label>Drop off address:</Form.Label>
                        <Form.Control
                            data-cy='drop-off-address'
                            name='dropOffAddress'
                            onChange={handleChange}
                            values={values.dropOffAddress}
                        />
                    </Form.Group>
                    <Button
                        block
                        data-cy='submit'
                        disabled={isSubmitting}
                        type='submit'
                        variant='primary'
                    >Submit</Button>
                </Form>
            )}
        </Formik>
    )
}

function RiderRequest() {
    const { data } = useAuthFetch(`${baseURL}/api/v1/addresses/`)
    return (
        <Row>
            <Col lg={12}>
                <Breadcrumb>
                    <LinkContainer to='/rider'>
                        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                    </LinkContainer>
                    <Breadcrumb.Item active>Request</Breadcrumb.Item>
                </Breadcrumb>
                <Card className='mb-3'>
                    <Card.Header>Request Trip</Card.Header>
                    <Card.Body>
                        <RiderRequestForm data={data} />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default RiderRequest;