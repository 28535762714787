import React, {useContext} from 'react'
import {Link} from "react-router-dom";
import {AuthContext} from "../contexts";

export default function Home() {
    const { user, isDriver, isRider } = useContext(AuthContext)
    return (
        <div className='middle-center'>
            <h1 className='landing logo'>Go Hyper Delivery</h1>
            {
                !user && (
                    <>
                        <Link
                            id='signUp'
                            className='btn btn-primary'
                            to='/driver-signup'
                        >Driver Sign up</Link>
                        <Link
                            id='logIn'
                            className='btn btn-primary'
                            to='/login'
                        >Log in</Link>
                    </>
                )
            }
            {
                isRider && (
                    <Link
                        className='btn btn-primary'
                        to='/rider'
                    >Dashboard</Link>
                )
            }
            {
                isDriver && (
                    <Link
                        className='btn btn-primary'
                        to='/driver'
                    >Dashboard</Link>
                )
            }
        </div>
    )
}