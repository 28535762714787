import React, {useContext} from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import RiderDashboard from './RiderDashboard';
import RiderDetail from './RiderDetail';
import RiderRequest from './RiderRequest';
import {AuthContext} from "../contexts";

function Rider() {
    const { isRider } = useContext(AuthContext)
    if (!isRider) {
        return <Redirect to='/' />
    }

    return (
        <Switch>
            <Route path='/rider/request' component={RiderRequest} />
            <Route path='/rider/:id' component={RiderDetail} />
            <Route component={RiderDashboard} />
        </Switch>
    )
}

export default Rider;