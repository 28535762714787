import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../contexts";

function useFetch(url, initialState, trigger = null, useAuth = false) {
  const auth = useContext(AuthContext);
  const [data, setData] = useState(initialState);
  const [error, setError] = useState(false);
  async function fetchUrl(source) {
    setError(false);
    try {
      let ax = axios;
      if (useAuth) {
        ax = auth.authAxios;
      }
      const response = await ax.get(url, { cancelToken: source.token });
      setData(response.data);
    } catch (err) {
      if (axios.isCancel(err)) {
        // cancelled
      } else {
        setError(err);
      }
    }
  }

  const callbacks = {
    setError,
    setData,
  };

  const deps = [];
  if (trigger !== undefined) {
    deps.push(trigger);
  }

  useEffect(() => {
    const source = axios.CancelToken.source();
    fetchUrl(source);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return { data, error, callbacks };
}

function useAuthFetch(url, initialState, trigger = null) {
  return useFetch(url, initialState, trigger, true);
}

export { useFetch, useAuthFetch };
