import React, { createContext, useState } from "react";
import axios from "axios";
import { api } from "../api";

// localstorage keys
const TAXI_AUTH = "taxi.auth";

interface ContextProps {
  tokens: {
    access: string;
    refresh: string;
  } | null;
  user: any;
  login: (email: string, password: string) => void;
  logout: () => void;
  driverSignup: (data: any) => void;
  authAxios: any;
  isDriver: boolean;
  isRider: boolean;
}

const DefaultProps = {
  tokens: null,
  user: null,
  login: (email, password) => null,
  logout: () => null,
  driverSignup: (data) => null,
  authAxios: null,
  isDriver: false,
  isRider: true,
};

export const AuthContext = createContext<ContextProps>(DefaultProps);

class AuthLocalStorageService {
  setStorage = (data) => {
    return localStorage.setItem(TAXI_AUTH, JSON.stringify(data));
  };

  clearStorage = () => {
    return localStorage.setItem(TAXI_AUTH, null);
  };

  getStorage = () => {
    return JSON.parse(localStorage.getItem(TAXI_AUTH));
  };

  getTokens = () => {
    const storage = this.getStorage();
    return storage
      ? {
          access: storage.access,
          refresh: storage.refresh,
        }
      : null;
  };

  getUser = () => {
    const storage = this.getStorage();
    return storage ? storage.user : null;
  };
}

const AuthContextProvider = ({ children }) => {
  const authStorageService = new AuthLocalStorageService();

  const [user, setUser] = useState(() => {
    return authStorageService.getUser();
  });

  const [tokens, setTokens] = useState(() => {
    try {
      return authStorageService.getTokens();
    } catch (e) {
      return null;
    }
  });

  async function driverSignup(data) {
    try {
      const response = await axios.post(api.auth.driverSignup, data);
      return { response, isError: false };
    } catch (error) {
      console.error(error);
      return { response: error, isError: true };
    }
  }

  async function login(email, password) {
    try {
      const response = await axios.post(api.auth.token, {
        username: email,
        password,
      });
      authStorageService.setStorage(response.data);
      setUser(response.data.user);
      setTokens({
        access: response.data.access,
        refresh: response.data.refresh,
      });
      return { response, isError: false };
    } catch (error) {
      console.error(error);
      return { response: error, isError: true };
    }
  }

  function logout() {
    authStorageService.clearStorage();
    setUser(null);
    // return axios.post(api.auth.logout).then(res => {
    //     setUser(null);
    //     history.push("/login");
    //     return res
    // });
  }

  const authAxios = axios.create();

  authAxios.interceptors.request.use((config) => {
    const { access } = authStorageService.getTokens();
    config.headers.Authorization = `Bearer ${access}`;
    return config;
  });

  authAxios.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      const originalRequest = error.config;
      const is401 = error.response.status === 401;
      if (is401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const { refresh } = authStorageService.getTokens();
        return axios.post(api.auth.refresh, { refresh }).then((res) => {
          const { access } = res.data;

          const data = {
            ...user,
            ...tokens,
            access,
          };

          // 1) put token to LocalStorage
          authStorageService.setStorage(data);
          setTokens({ ...tokens, access });

          // 2) return originalRequest object with Axios.
          return authAxios(originalRequest);
        });
      }
      return Promise.reject(error);
    }
  );

  return (
    <AuthContext.Provider
      value={{
        user,
        tokens,
        login,
        logout,
        driverSignup,
        authAxios,
        isDriver: user && user.group === "driver",
        isRider: user && user.group === "rider",
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
