import React, { useContext } from "react";
import { Button, Container, Form, Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { SWRConfig } from "swr";
import Home from "./components/Home";
import BusinessSignUp from "./components/businessSignUp";
import SignUp from "./components/signUp";
import LogIn from "./components/LogIn";
import Driver from "./components/Driver";
import Rider from "./components/Rider";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import AuthContextProvider, { AuthContext } from "./contexts/AuthContext";
import { PrivateRoute } from "./components/PrivateRoute";

// const fetcherExample = {
//   fetcher: async (url) => {
//     const res = await fetch(url, {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${state.tokens?.access}`,
//       },
//     });
//     if (!res.ok()) {
//       if (res.statusCode === 401)
//         throw new NotAuthorizedError(res.statusMessage);
//       else throw new Error(res.statusMessage);
//     }
//     return res.json();
//   },
// }

function WithSWR({ children }) {
  const { authAxios } = useContext(AuthContext);
  return (
    <SWRConfig
      value={{
        fetcher: (url) => authAxios(url).then((res) => res.data),
      }}
    >
      {children}
    </SWRConfig>
  );
}

function Layout({ children }) {
  const { isRider, user, logout } = useContext(AuthContext);
  const history = useHistory();

  function handleLogout() {
    logout();
    history.push("/login");
  }

  return (
    <WithSWR>
      <Navbar bg="light" expand="lg" variant="light">
        <LinkContainer to="/">
          <Navbar.Brand className="logo">Go Hyper</Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle />
        <Navbar.Collapse>
          {isRider && (
            <Nav className="mr-auto">
              <LinkContainer to="/rider/request">
                <Nav.Link>Request a Delivery</Nav.Link>
              </LinkContainer>
            </Nav>
          )}
          {user && (
            <Form inline className="ml-auto">
              <Button type="button" onClick={() => handleLogout()}>
                Log out
              </Button>
            </Form>
          )}
        </Navbar.Collapse>
      </Navbar>
      <Container className="pt-3">{children}</Container>
      <ToastContainer />
    </WithSWR>
  );
}

function App() {
  return (
    <AuthContextProvider>
      <BrowserRouter>
        <Layout>
          <Switch>
            <Route path="/business-signup" component={BusinessSignUp} />
            <Route path="/driver-signup" component={SignUp} />
            <Route path="/login" component={LogIn} />
            <PrivateRoute path="/driver" component={Driver} />
            <PrivateRoute path="/rider" component={Rider} />
            <Route exact path="/" component={Home} />
          </Switch>
        </Layout>
      </BrowserRouter>
    </AuthContextProvider>
  );
}

export default App;
