import React, {useContext } from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import {Alert, Breadcrumb, Card, Col, Row, Button, Form } from 'react-bootstrap';
import {AuthContext} from "../contexts";
import { useHistory, Redirect } from 'react-router-dom';


function LogIn() {
    const history = useHistory()
    const { user, login } = useContext(AuthContext)

    const onSubmit = async (values, actions) => {
        try {
            const {response, isError } = await login(
                values.email,
                values.password
            );
            if (isError) {
                const data = response.response.data;
                for (const value in data){
                    actions.setFieldError(value, data[value].join(' '));
                }
            } else {
                history.push('/')
            }
        }
        catch (e) {
            console.error(e)
        }
        finally {
            actions.setSubmitting(false)
        }
    };

    if (user) {
        return <Redirect to={'/'} />
    }

    return (
        <Row>
            <Col lg={12}>
                <Breadcrumb>
                    <Breadcrumb.Item href='/'>Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Log in</Breadcrumb.Item>
                </Breadcrumb>

                <Card>
                    <Card.Header>Log in</Card.Header>
                    <Card.Body>
                        <Formik
                            initialValues={{
                            email: '',
                            password:''
                        }}
                        onSubmit={onSubmit}
                            >

                       {({
                                errors,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                values

                            }) => (
                                <> {
                                '__all__' in errors && <Alert variant="danger">
                               { errors['__all__']}
                           </Alert>
                                   }
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Form.Group controlId='email'>
                                        <Form.Label>Email:</Form.Label>
                                        <Form.Control
                                            name='email'
                                            onChange={handleChange}
                                            value={values.email}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId='password'>
                                        <Form.Label>Password:</Form.Label>
                                        <Form.Control
                                            name='password'
                                            type='password'
                                            onChange={handleChange}
                                            value={values.password}
                                        />
                                    </Form.Group>
                                    <Button block disabled={isSubmitting} type='submit' variant='primary'>
                                        {isSubmitting ? (
                                            <span>Logging in...</span>
                                        ) : (
                                            <span>Log in</span>
                                        )}
                                    </Button>
                                </Form>
                                </>
                            )}
                        </Formik>

                    </Card.Body>
                    <p className='mt-3 text-center'>
                        Don't have an account? <Link to='/business-signup'>Sign up your business!</Link>
                    </p>
                    <p className='mt-3 text-center'>
                        <Link to='/driver-signup'>Driver Sign up</Link>
                    </p>
                </Card>
            </Col>
        </Row>
    );
}

export default LogIn;
