import React, {useContext} from 'react';
import {Switch, Route, Redirect} from "react-router-dom/";
import DriverDashboard from './DriverDashboard';
import DriverDetail from './DriverDetail';
import {AuthContext} from "../contexts";

function Driver () {
    const { isDriver  } = useContext(AuthContext)

    if (!isDriver) {
        return <Redirect to='/' />
    }

    return (
        <Switch>
            <Route path='/driver/:id' component={DriverDetail} />
            <Route component={DriverDashboard} />
        </Switch>
    );
}

export default Driver;

