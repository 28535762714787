import React, {useContext} from 'react';
import {Formik} from 'formik';
import { useHistory, Link } from 'react-router-dom';
import {Breadcrumb, Row, Col, Card, Button, Form} from "react-bootstrap";
import {AuthContext} from "../contexts";

function SignUp() {
    const { driverSignup } = useContext(AuthContext)
    const history = useHistory()

    const onSubmit = async (values, actions) => {
        const formData = new FormData();
        formData.append('email', values.email);
        formData.append('full_name', values.fullName);
        formData.append('password1', values.password);
        formData.append('password2', values.password);
        formData.append('licence', values.licence);
        formData.append('phone_number', values.phoneNumber);
        formData.append('photo', values.photo);

        try {
            const {response, isError } = await driverSignup(formData);
            if (isError) {
                const data = response.response.data;
                for (const value in data){
                    actions.setFieldError(value, data[value].join(' '));
                }
            } else {
                history.push('/login')
            }
        }
        catch (response) {
            const data = response.response.data
            for (const value in data){
                actions.setFieldError(value, data[value].join(' '))
            }
        }
        finally {
            actions.setSubmitting(false)
        }
    };

    return (
        <Row>
            <Col lg={12}>
                <Breadcrumb>
                    <Breadcrumb.Item href='/'>Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Driver Sign up</Breadcrumb.Item>
                </Breadcrumb>
                <Card>
                    <Card.Header>Driver Sign up</Card.Header>
                    <Card.Body>
                            <Formik
                                initialValues={{
                                    fullName: '',
                                    email: '',
                                    password: '',
                                    phoneNumber: '',
                                    licence: '',
                                    photo: []
                                }}
                                onSubmit={onSubmit}
                            >
                                {({
                                      errors,
                                      handleChange,
                                      handleSubmit,
                                      isSubmitting,
                                      setFieldValue,
                                      values
                                  }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                       <Form.Group controlId='fullName'>
                                            <Form.Label>Full Name:</Form.Label>
                                            <Form.Control
                                                className={ 'fullName' in errors ? 'is-invalid' : '' }
                                                name='fullName'
                                                onChange={handleChange}
                                                values={values.fullName}
                                            />
                                            {
                                                'fullName' in errors &&
                                                <Form.Control.Feedback type='invalid'>{ errors.fullName }</Form.Control.Feedback>
                                            }
                                        </Form.Group>
                                        <Form.Group controlId='email'>
                                            <Form.Label>Email:</Form.Label>
                                            <Form.Control
                                                className={ 'email' in errors ? 'is-invalid': ''}
                                                name='email'
                                                onChange={handleChange}
                                                values={values.email}
                                            />
                                            {
                                                'email' in errors &&
                                                <Form.Control.Feedback type='invalid'>{ errors.email }</Form.Control.Feedback>
                                            }
                                        </Form.Group>
                                        <Form.Group controlId='password'>
                                            <Form.Label>Password:</Form.Label>
                                            <Form.Control
                                                className={ 'password' in errors ? 'is-invalid' : '' }
                                                name='password'
                                                onChange={handleChange}
                                                type='password'
                                                value={values.password}
                                            />
                                            {
                                                'password' in errors &&
                                                <Form.Control.Feedback type='invalid'>{ errors.password }</Form.Control.Feedback>
                                            }
                                        </Form.Group>
                                        <Form.Group controlId='phoneNumber'>
                                            <Form.Label>Phone Number:</Form.Label>
                                            <Form.Control
                                                className={ 'phoneNumber' in errors ? 'is-invalid': ''}
                                                name='phoneNumber'
                                                onChange={handleChange}
                                                values={values.phoneNumber}
                                            />
                                            {
                                                'phoneNumber' in errors &&
                                                <Form.Control.Feedback type='invalid'>{ errors.phoneNumber }</Form.Control.Feedback>
                                            }
                                        </Form.Group>
                                        <Form.Group controlId='licence'>
                                            <Form.Label>Licence:</Form.Label>
                                            <Form.Control
                                                className={ 'licence' in errors ? 'is-invalid': ''}
                                                name='licence'
                                                onChange={handleChange}
                                                values={values.licence}
                                            />
                                            {
                                                'licence' in errors &&
                                                <Form.Control.Feedback type='invalid'>{ errors.licence }</Form.Control.Feedback>
                                            }
                                        </Form.Group>
                                        <Form.Group controlId='photo'>
                                            <Form.Label>Photo:</Form.Label>
                                            <Form.Control
                                                className={ 'photo' in errors ? 'is-invalid' : '' }
                                                name='photo'
                                                onChange={event => {
                                                    setFieldValue('photo', event.currentTarget.files[0])
                                                }}
                                                type='file'
                                            />
                                            {
                                                'photo' in errors && <Form.Control.Feedback type='invalid'>{ errors.photo }</Form.Control.Feedback>
                                            }
                                        </Form.Group>
                                        <Button block type='submit' variant='primary' disabled={isSubmitting}>
                                            {isSubmitting ? "Creating account ..." : "Sign up"}
                                        </Button>
                                    </Form>
                                )}
                            </Formik>
                        </Card.Body>
                        <p className="mt-3 text-center">
                            Already have an account? <Link to='/login'>Log in!</Link>
                        </p>
                </Card>
            </Col>
        </Row>
);
}

export default SignUp;