import React, {useState} from 'react';
import {Formik} from 'formik';
import {Link, Redirect } from 'react-router-dom';
import {Breadcrumb, Row, Col, Card, Button, Form} from "react-bootstrap";
import axios from 'axios';
import {baseURL} from "../config";

export default function BusinessSignUp() {
    const [isSubmitted, setSubmitted ] = useState(false)

    const onSubmit = async (values, actions) => {
        const url = `${baseURL}/api/v1/trips/business/signup/`
        console.log(values)
        const formData = new FormData()
        formData.append('email', values.email);
        formData.append('password1', values.password);
        formData.append('password2', values.password);
        formData.append('phone_number', values.phoneNumber);
        formData.append('abn_acn', values.abnAcn);
        formData.append('business_name', values.businessName);
        formData.append('full_name', values.fullName);

        try {
            await axios.post(url, formData)
            setSubmitted(true);
        }
        catch (response) {
            const data = response.response.data
            for (const value in data){
                actions.setFieldError(value, data[value].join(' '))
            }
        }
        finally {
            actions.setSubmitting(false)
        }
    };

    if (isSubmitted) {
        return <Redirect to='/' />
    }
    return (
        <Row>
            <Col lg={12}>
                <Breadcrumb>
                    <Breadcrumb.Item href='/'>Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Business Sign Up</Breadcrumb.Item>
                </Breadcrumb>
                <Card>
                    <Card.Header>Sign up your Business</Card.Header>
                    <Card.Body>
                        <Formik
                            initialValues={{
                                fullName: '',
                                businessName: '',
                                email: '',
                                password1: '',
                                password2: '',
                                phoneNumber: '',
                                abnAcn: '',
                            }}
                            onSubmit={onSubmit}
                        >
                            {({
                                  errors,
                                  handleChange,
                                  handleSubmit,
                                  isSubmitting,
                                  setFieldValue,
                                  values
                              }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Form.Group controlId='fullName'>
                                        <Form.Label>Full Name:</Form.Label>
                                        <Form.Control
                                            className={ 'fullName' in errors ? 'is-invalid' : '' }
                                            name='fullName'
                                            onChange={handleChange}
                                            values={values.fullName}
                                        />
                                        {
                                            'fullName' in errors &&
                                            <Form.Control.Feedback type='invalid'>{ errors.fullName }</Form.Control.Feedback>
                                        }
                                    </Form.Group>
                                    <Form.Group controlId='businessName'>
                                        <Form.Label>Business Name:</Form.Label>
                                        <Form.Control
                                            className={ 'businessName' in errors ? 'is-invalid' : '' }
                                            name='businessName'
                                            onChange={handleChange}
                                            values={values.businessName}
                                        />
                                        {
                                            'businessName' in errors &&
                                            <Form.Control.Feedback type='invalid'>{ errors.businessName }</Form.Control.Feedback>
                                        }
                                    </Form.Group>
                                    <Form.Group controlId='email'>
                                        <Form.Label>Email:</Form.Label>
                                        <Form.Control
                                            className={ 'email' in errors ? 'is-invalid': ''}
                                            name='email'
                                            onChange={handleChange}
                                            values={values.email}
                                        />
                                        {
                                            'email' in errors &&
                                            <Form.Control.Feedback type='invalid'>{ errors.email }</Form.Control.Feedback>
                                        }
                                    </Form.Group>
                                    <Form.Group controlId='password'>
                                        <Form.Label>Password:</Form.Label>
                                        <Form.Control
                                            className={ 'password' in errors ? 'is-invalid' : '' }
                                            name='password'
                                            onChange={handleChange}
                                            type='password'
                                            value={values.password}
                                        />
                                        {
                                            'password' in errors &&
                                            <Form.Control.Feedback type='invalid'>{ errors.password }</Form.Control.Feedback>
                                        }
                                    </Form.Group>
                                    <Form.Group controlId='phoneNumber'>
                                        <Form.Label>Phone Number:</Form.Label>
                                        <Form.Control
                                            className={ 'phoneNumber' in errors ? 'is-invalid': ''}
                                            name='phoneNumber'
                                            onChange={handleChange}
                                            values={values.phoneNumber}
                                        />
                                        {
                                            'phoneNumber' in errors &&
                                            <Form.Control.Feedback type='invalid'>{ errors.phoneNumber }</Form.Control.Feedback>
                                        }
                                    </Form.Group>
                                    <Form.Group controlId='abnAcn'>
                                        <Form.Label>ABN or ACN</Form.Label>
                                        <Form.Control
                                            className={ 'abnAcn' in errors ? 'is-invalid': ''}
                                            name='abnAcn'
                                            onChange={handleChange}
                                            values={values.abnAcn}
                                        />
                                        {
                                            'abnAcn' in errors &&
                                            <Form.Control.Feedback type='invalid'>{ errors.abnAcn }</Form.Control.Feedback>
                                        }
                                    </Form.Group>
                                    <Button block type='submit' variant='primary' disabled={isSubmitting}>
                                        {isSubmitting ? "Creating account ..." : "Sign up"}
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </Card.Body>
                    <p className="mt-3 text-center">
                        Already have an account? <Link to='/log-in'>Log in!</Link>
                    </p>
                </Card>
            </Col>
        </Row>
    );
}