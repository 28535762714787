import React, { useContext, useEffect, useState } from "react";
import { Breadcrumb, Card, Col, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { webSocket } from "rxjs/webSocket";
import TripMedia from "./TripMedia";
import { useAuthFetch } from "../helpers";
import { wsBaseURL } from "../config";
import { AuthContext } from "../contexts";
import Map from "./Map";
import { api } from "../api";

function RiderDetailWithData({ trip }) {
  const {
    tokens: { access },
  } = useContext(AuthContext);
  const [driverLocation, setDriverLocation] = useState(null);
  const [testLoc, setTestLoc] = useState({
    lat: -25,
    lon: 28,
  });

  useEffect(() => {
    const ws = webSocket(`${wsBaseURL}/taxi/?token=${access}`);
    const subscription = ws.subscribe((message) => {
      if (message.type === "echo.message") {
        if (message.data && message.data.trip) {
          if (message.data.trip === trip.id) {
            setDriverLocation({
              lat: parseInt(message.data.lat, 10),
              lon: parseInt(message.data.lon, 10),
            });
          }
        }
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [access]);

  // useEffect(() => {
  //     setInterval(() => {
  //         setTestLoc({
  //             lat: testLoc.lat + 0.25,
  //             lon: testLoc.lon + 0.25
  //         })
  //     }, 5000)
  // }, [])

  return (
    <Row>
      <Col lg={12}>
        <Breadcrumb>
          <LinkContainer to="/rider">
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          </LinkContainer>
          <Breadcrumb.Item active>Trip</Breadcrumb.Item>
        </Breadcrumb>
        <Card className="mb-3" data-cy="trip-card">
          <Card.Header>Trip</Card.Header>
          <Card.Body>
            {trip ? (
              <TripMedia trip={trip} otherGroup="driver" />
            ) : (
              <>Loading...</>
            )}
          </Card.Body>
        </Card>
        {driverLocation && trip && (
          <Map
            lat={driverLocation.lat}
            lng={driverLocation.lon}
            // lat={testLoc.lat}
            // lng={testLoc.lon}
            zoom={13}
            pickUpAddress={trip.pickup_address}
            dropOffAddress={trip.drop_off_address}
          />
        )}
      </Col>
    </Row>
  );
}

function RiderDetail({ match }) {
  const { data: trip } = useAuthFetch(api.trips.retrieve(match.params.id));
  return (
    <>
      {!trip && "Loading ..."}
      {trip && <RiderDetailWithData trip={trip} />}
    </>
  );
}

export default RiderDetail;
