import React, { useContext, useEffect, useState } from "react";
import { Breadcrumb, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { webSocket } from "rxjs/webSocket";
import TripCard from "./TripCard";
import { useAuthFetch } from "../helpers";
import { wsBaseURL } from "../config";
import { AuthContext } from "../contexts";
import { api } from "../api";

function RiderDashboardWithData({ data }) {
  const {
    tokens: { access },
  } = useContext(AuthContext);
  const [trips, setTrips] = useState(data);

  const getRequestedTrips = () => {
    return trips.filter((trip) => {
      return trip.status === "REQUESTED";
    });
  };

  const getCurrentTrips = () => {
    return trips.filter((trip) => {
      return (
        trip.driver !== null &&
        trip.status !== "REQUESTED" &&
        trip.status !== "COMPLETED"
      );
    });
  };

  const getCompletedTrips = () => {
    return trips.filter((trip) => {
      return trip.status === "COMPLETED";
    });
  };

  const updateToast = (trip) => {
    if (trip.status === "STARTED") {
      toast.info(`Driver ${trip.driver.username} is coming to pick you up.`);
    } else if (trip.status === "IN_PROGRESS") {
      toast.info(
        `Driver ${trip.driver.username} is headed to your destination.`
      );
    } else if (trip.status === "COMPLETED") {
      toast.info(`Driver ${trip.driver.username} has dropped you off.`);
    }
  };

  useEffect(() => {
    const ws = webSocket(`${wsBaseURL}/taxi/?token=${access}`);
    const subscription = ws.subscribe((message) => {
      setTrips((prevTrips) => [
        ...prevTrips.filter((trip) => trip.id !== message.data.id),
        message.data,
      ]);
      updateToast(message.data);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [access]);

  return (
    <Row>
      <Col lg={12}>
        <Breadcrumb>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Dashboard</Breadcrumb.Item>
        </Breadcrumb>
        <TripCard
          title="Current Trip"
          trips={getCurrentTrips()}
          group="rider"
          otherGroup="driver"
        />
        <TripCard
          title="Requested Trips"
          trips={getRequestedTrips()}
          group="rider"
          otherGroup="driver"
        />
        <TripCard
          title="Recent Trips"
          trips={getCompletedTrips()}
          group="rider"
          otherGroup="driver"
        />
      </Col>
    </Row>
  );
}

function RiderDashboard() {
  const { data: trips } = useAuthFetch(api.trips.list, []);
  return (
    <>
      {!trips && "Loading ..."}
      {trips && <RiderDashboardWithData data={trips} />}
    </>
  );
}

export default RiderDashboard;
