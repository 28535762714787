import { baseURL } from "../config";

const baseAPI = `${baseURL}/api/v1`;

export const api = {
  auth: {
    token: `${baseAPI}/token/`,
    refresh: `${baseAPI}/token/refresh/`,
    driverSignup: `${baseAPI}/trips/driver/signup/`,
  },
  orders: {
    retrieve: (id) => `${baseAPI}/orders/${id}/`,
    verifyPrescription: (id) => `${baseAPI}/orders/prescription/verify/${id}/`,
  },
  trips: {
    list: `${baseAPI}/trips/`,
    retrieve: (id) => `${baseAPI}/trips/${id}/`,
  },
};
