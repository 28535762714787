import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Col,
  Row,
  Form,
  Image,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Formik } from "formik";
import { webSocket } from "rxjs/webSocket";
import useSWR from "swr";
import TripMedia from "./TripMedia";
import { useAuthFetch } from "../helpers";
import { AuthContext } from "../contexts";
import { wsBaseURL } from "../config";
import { api } from "../api";
import { errorHandler } from "../utils";
import { OrderItemProps, OrderProps } from "../models";

function UploadPhotoForm({
  orderItem,
  onSuccess,
}: {
  orderItem: OrderItemProps;
  onSuccess: () => void;
}) {
  const [image, setImage] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const { authAxios } = useContext(AuthContext);

  const onChange = (e) => {
    setImage(e.target.files[0]);
  };

  const onSubmit = async (values, actions) => {
    if (!image) {
      setError("Please select an image");
      return;
    }
    try {
      const formData = new FormData();
      formData.append("image", image, image.name);
      await authAxios
        .post(api.orders.verifyPrescription(orderItem.id), formData)
        .then((_res) => {
          setSuccess(true);
          const { data } = _res;
          if (data.order_verified) {
            onSuccess();
          }
          return data;
        });
    } catch (e) {
      console.log(e);
      if (e.response.data) {
        const { data } = e.response;
        for (const value in data) {
          actions.setFieldError(value, data[value].join(" "));
        }
      } else {
        setError(errorHandler(e));
      }
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Card className="mb-3" data-cy="trip-card">
      <Card.Header>
        Upload prescription photo for {orderItem.item.title}
      </Card.Header>
      <Card.Body>
        {(orderItem.uploaded_prescription &&
          orderItem.uploaded_prescription.verified) ||
        success ? (
          <Alert variant="success">This item has been verified</Alert>
        ) : (
          <>
            {orderItem.item.images.length > 0 && (
              <Image
                src={`http://127.0.0.1:8000/media/${orderItem.item.images[0]}`}
                rounded
              />
            )}
            <Formik initialValues={{}} onSubmit={onSubmit}>
              {({ errors, handleSubmit, isSubmitting }) => (
                <>
                  {error && <Alert variant="danger">{error}</Alert>}
                  {"__all__" in errors && (
                    <Alert variant="danger">{errors.__all__}</Alert>
                  )}
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group controlId="password">
                      <Form.Label>Prescription Photo:</Form.Label>
                      <Form.Control
                        required
                        name="image"
                        type="file"
                        accept="image/*"
                        capture="environment"
                        onChange={onChange}
                      />
                    </Form.Group>
                    <Button
                      block
                      disabled={isSubmitting}
                      type="submit"
                      variant="primary"
                    >
                      {isSubmitting ? (
                        <span>Verifying...</span>
                      ) : (
                        <span>Submit</span>
                      )}
                    </Button>
                  </Form>
                </>
              )}
            </Formik>
          </>
        )}
      </Card.Body>
    </Card>
  );
}

function GosupplyOrderForm({
  trip,
  onComplete,
}: {
  trip: any;
  onComplete: () => void;
}) {
  const { data: order, revalidate } = useSWR<OrderProps>(
    api.orders.retrieve(trip.gosupply_order)
  );
  return (
    <>
      {!order && "Loading order..."}
      {order && (
        <>
          {order.prescription_verification &&
          order.prescription_verification.verified ? (
            <>
              <Alert variant="success">
                This order is verified. You can complete the order
              </Alert>
              <Button
                data-cy="status-button"
                block
                variant="primary"
                onClick={() => onComplete()}
              >
                Complete trip
              </Button>
            </>
          ) : (
            <>
              {order.order_items
                .filter(
                  (orderItem) =>
                    orderItem.item.requires_medical_licence === true
                )
                .map((orderItem) => {
                  return (
                    <UploadPhotoForm
                      key={orderItem.id}
                      orderItem={orderItem}
                      onSuccess={() => revalidate()}
                    />
                  );
                })}
            </>
          )}
        </>
      )}
    </>
  );
}

function DriverDetailWithData({ data }) {
  const [trip, setTrip] = useState(data);
  const {
    tokens: { access },
    user,
  } = useContext(AuthContext);

  useEffect(() => {
    function broadcastLocation() {
      if (window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition((position) => {
          const ws = webSocket(`${wsBaseURL}/taxi/?token=${access}`);
          const subscription = ws.subscribe();
          ws.next({
            type: "broadcast.location",
            data: {
              trip: trip.id,
              lat: position.coords.latitude,
              lon: position.coords.longitude,
            },
          });
          subscription.unsubscribe();
        });
      }
    }

    // if in progress or started send location
    if (trip) {
      if (trip.status === "STARTED" || trip.status === "IN_PROGRESS") {
        setInterval(() => broadcastLocation(), 20000);
      }
    }
  }, [access, trip]);

  const updateTripStatus = (status) => {
    const driver = user;
    const updatedTrip = { ...trip, driver, status };
    const ws = webSocket(`${wsBaseURL}/taxi/?token=${access}`);
    ws.subscribe();
    ws.next({
      type: "update.trip",
      data: {
        ...updatedTrip,
        driver: updatedTrip.driver.id,
        rider: updatedTrip.rider.id,
      },
    });
    setTrip(updatedTrip);
  };

  let tripMedia;

  if (trip === null) {
    tripMedia = <>Loading...</>;
  } else {
    tripMedia = <TripMedia trip={trip} otherGroup="rider" group={null} />;
  }
  return (
    <Row>
      <Col lg={12}>
        <Breadcrumb>
          <LinkContainer to="/driver">
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          </LinkContainer>
          <Breadcrumb.Item active>Trip</Breadcrumb.Item>
        </Breadcrumb>
        <Card className="mb-3" data-cy="trip-card">
          <Card.Header>Trip</Card.Header>
          <Card.Body>{tripMedia}</Card.Body>
          <Card.Footer>
            {trip !== null && trip.status === "REQUESTED" && (
              <Button
                data-cy="status-button"
                block
                variant="primary"
                onClick={() => updateTripStatus("STARTED")}
              >
                Drive to pick up
              </Button>
            )}
            {trip !== null && trip.status === "STARTED" && (
              <Button
                data-cy="status-button"
                block
                variant="primary"
                onClick={() => updateTripStatus("IN_PROGRESS")}
              >
                Drive to drop off
              </Button>
            )}
            {trip !== null && trip.status === "IN_PROGRESS" && (
              <>
                {trip.gosupply_order ? (
                  <GosupplyOrderForm
                    trip={trip}
                    onComplete={() => updateTripStatus("COMPLETED")}
                  />
                ) : (
                  <Button
                    data-cy="status-button"
                    block
                    variant="primary"
                    onClick={() => updateTripStatus("COMPLETED")}
                  >
                    Complete trip
                  </Button>
                )}
              </>
            )}
            {trip !== null &&
              !["REQUESTED", "STARTED", "IN_PROGRESS"].includes(
                trip.status
              ) && <span className="text-center">Completed</span>}
          </Card.Footer>
        </Card>
      </Col>
    </Row>
  );
}

function DriverDetail({ match }) {
  const { data: trip } = useAuthFetch(api.trips.retrieve(match.params.id));
  return (
    <>
      {!trip && "Loading ..."}
      {trip && <DriverDetailWithData data={trip} />}
    </>
  );
}

export default DriverDetail;
